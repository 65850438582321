import React from 'react'
import { messages } from '../../../../translations/messages'
import { useIntl } from "react-intl"
import Form from '../form'
import { Grid } from '@material-ui/core'
import { Field } from '../../../common'

const ExtraInformations = ({ data }) => {
    const intl = useIntl();
    const { id, dateCreated, dateModified, statusID } = data;
    return (
        <Form
            title={intl.formatMessage(messages.EXTRA_INFORMATIONS)}>
            <Grid container spacing={2}>
                <Grid item md={12}>
                    <Field label={"ID"} value={id} />
                </Grid>
                <Grid item md={12}>
                    <Field label={"Created By"} value={"ADMIN"} />
                </Grid>
                <Grid item md={12}>
                    <Field label={"Modified By"} value={"ADMIN"} />
                </Grid>
                <Grid item md={12}>
                    <Field label={"Date Created"} value={dateCreated} format="DATE_TIME" />
                </Grid>
                <Grid item md={12}>
                    <Field label={"Date Modified"} value={dateModified} format="DATE_TIME" />
                </Grid>
                <Grid item md={12}>
                    <Field label={"Status"} value={statusID > 0 ? "ACTIVE" : "PASSIVE"} coloredText={statusID ? "SUCCESS" : "WARNING"} />
                </Grid>
            </Grid>
        </Form>
    )
}

export default ExtraInformations