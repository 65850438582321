import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { _callService } from '../../services/common'
import FilterModel from '../../constants/services/filter/filterModel.json'

const DEFAULT_ID_KEY = "id";
const DEFAULT_LABEL_KEY = "name";

class SelectBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: true
        }
    }
    getData() {

        const { serviceEndpoint } = this.props;
        var _serviceEndpoint = serviceEndpoint;
        if (!serviceEndpoint)
            return;

        // if (params) {
        //     var callService = true;
        //     Object.keys(params).forEach(function (key) {
        //         var val = params[key];
        //         if (!val)
        //             callService = false;
        //         _serviceEndpoint = _serviceEndpoint.replace("{" + key + "}", val);
        //     });
        //     if (!callService) {
        //         return;
        //     }
        // }

        _callService(_serviceEndpoint, FilterModel).then(response => {
            let data = [];
            for (let item of response) {
                let newItem = {};
                const valueKey = (this.props.valueKey ? this.props.valueKey : DEFAULT_ID_KEY);
                const nameKey = (this.props.optionLabel ? this.props.optionLabel : DEFAULT_LABEL_KEY);
                newItem[valueKey] = item[valueKey];
                newItem[nameKey] = item[nameKey];
                if (this.props.includeProps) {
                    for (var i = 0; i < this.props.includeProps.length; i++) {
                        var key = this.props.includeProps[i];
                        newItem[key] = item[key];
                    }
                }
                data.push(newItem);
            }
            this.setState({ data, loading: false });
        })
    }
    componentDidUpdate(prevProps) {
        if (!this.props.params)
            return;

        if (JSON.stringify(this.props.params) !== JSON.stringify(prevProps.params)) {
            this.getData();
        }
    }
    componentDidMount() {
        this.getData();
    }
    render() {
        const { optionLabel, multiple, defaultValue, disabled, onChange, onClick, label, variant, className, margin, freeSolo, autoSelect } = this.props;
        return (
            <Autocomplete
                freeSolo={freeSolo}
                autoSelect={autoSelect}
                multiple={multiple}
                className={className}
                id="auto-complate"
                style={{ margin }}
                options={this.state.data}
                // getOptionSelected={(option, value) => option.title === defaultValue.name} 
                value={defaultValue}
                disabled={this.state.loading || disabled}
                onChange={onChange}
                onClick={onClick}
                getOptionLabel={option => option[(optionLabel ? optionLabel : DEFAULT_LABEL_KEY)]}
                renderInput={params => (
                    <TextField {...params} variant={variant ? variant : "outlined"} label={label} fullWidth />
                )} />
        );
    }
}

export default SelectBox