import NotFound from '../views/errors/not_found'
import PermissionDenied from '../views/errors/permission_denied'

const publicRoutes = {
    NotFound: {
        component: NotFound,
        path: '/not-found'
    },
    PermissionDenied: {
        component: PermissionDenied,
        path: 'permission-denied'
    }
};

export default publicRoutes