import axios from 'axios';
import AppConfig from '../config.json';
import { getAuthorizationKey } from '../helpers/account/Authorization'
import { getLocale } from '../helpers/common/localization'
import { getLocalStorage } from '../helpers/common/localStorage'
import { _refreshToken } from './account'

export const refreshTokenControl = () => new Promise((resolve, reject) => {
    const expiration = getLocalStorage('expiration');
    const token = getAuthorizationKey();
    if (token) {
        const dateNow = new Date();
        const expirationDate = new Date(expiration);
        if (dateNow >= expirationDate) {
            _refreshToken().then(response => {
                resolve(true);
            }).catch(err => {
                reject(true);
            })
        } else
            resolve(true);
    } else
        resolve(true);
});

export const authorizeControlOnError = (error) => new Promise((resolve, reject) => {
    if (error.response && error.response.status === 401) {
        _refreshToken().then(response => {
            resolve('token_refreshed');
        }).catch(errorMessage => {
            resolve(errorMessage);
        })
    } else {
        resolve(false)
    }
});

export const getService = (url, setAuthroziation = true) => new Promise((resolve, reject) => {
    url = AppConfig.serviceUrl + url;
    var config = {};
    if (setAuthroziation) {
        config.headers = {
            "Authorization": getAuthorizationKey(),
            "Accept-Language": getLocale()
        }
    }
    axios.get(url, config)
        .then(response => resolve(response.data))
        .catch(err => { reject(err); });
});

export const postService = (url, data, setAuthroziation = true) => new Promise((resolve, reject) => {
    url = AppConfig.serviceUrl + url;
    var config = {};
    if (setAuthroziation) {
        config.headers = {
            "Authorization": getAuthorizationKey(),
            "Accept-Language": getLocale(),
            "Content-Type": "application/json"
        }
    }
    axios.post(url, data, config)
        .then(response => resolve(response.data))
        .catch(err => { reject(err); });
});


export const deleteService = (url, setAuthroziation = true) => new Promise((resolve, reject) => {
    url = AppConfig.serviceUrl + url;
    var config = {};
    if (setAuthroziation) {
        config.headers = {
            "Authorization": getAuthorizationKey(),
            "Accept-Language": getLocale()
        }
    }
    axios.delete(url, config)
        .then(response => resolve(response.data))
        .catch(err => {
            reject(err)
        });
});