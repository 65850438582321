import React from 'react'
import { Button } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import ListPage from '../../../components/views/pages/listPage'
import ButtonTypes from '../../../constants/view/buttonTypes.json'
import { messages } from '../../../translations/messages'
import { injectIntl } from "react-intl"
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { getList, approve, reject } from '../../../redux/actions/visa/list/pending_approval_user_actions'
import ColumnTypes from '../../../constants/view/columnTypes.json'

const EDIT_PAGE_URL = "";

class PendingApprovalUserList extends React.Component {
    componentDidMount() {
        this.onApprove = this.onApprove.bind(this);
        this.onReject = this.onReject.bind(this);
    }
    onApprove(rowData) {
        this.props.onApproveObject(rowData.userID, rowData.companyID);
        window.location.reload();
    }
    onReject(rowData) {
        this.props.onRejectObject(rowData.userID, rowData.companyID);
        window.location.reload();
    }
    render() {
        const { intl } = this.props;
        return (
            <div id="user_list">
                <ListPage
                    breadCrumbData={
                        {
                            title: intl.formatMessage(messages.ALL_USERS),
                            items: [
                                {
                                    title: intl.formatMessage(messages.DASHBOARD),
                                    link: "/"
                                }
                            ]
                        }
                    }
                    buttonsData={
                        [

                        ]
                    }
                    dataTable={
                        {
                            getList: this.props.onGetList,
                            listModel: this.props.listModel,
                            editPageUrl: EDIT_PAGE_URL,
                            columns: [
                                { title: intl.formatMessage(messages.ID), field: 'userID', },
                                { title: intl.formatMessage(messages.CURRENT_CODE), field: 'company.currentNo', },
                                { title: intl.formatMessage(messages.COMPANY_NAME), field: 'company.name', },
                                { title: intl.formatMessage(messages.USER_NAME), field: 'user.username', },
                                { title: intl.formatMessage(messages.FİRST_NAME), field: 'user.firstname', },
                                { title: intl.formatMessage(messages.LAST_NAME), field: 'user.lastname', },
                                { title: intl.formatMessage(messages.IS_APPROVED), field: 'isApproved', columnType: ColumnTypes.BOOL },
                                { customRender: ((rowData) => <Button startIcon={<CheckIcon />} className="greenButton" variant="contained" color="primary" onClick={() => this.onApprove(rowData)}>{intl.formatMessage(messages.APPROVE)}</Button>) },
                                { customRender: ((rowData) => <Button startIcon={<DeleteIcon />} className="redButton" variant="contained" onClick={() => this.onReject(rowData)}> {intl.formatMessage(messages.DELETE)}</Button>) },
                            ]
                        }
                    }
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        listModel: state.listPendingApprovalUserReducer.listModel
    };
}

const mapDispatchToProps = {
    onGetList: getList,
    onApproveObject: approve,
    onRejectObject: reject
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(PendingApprovalUserList)));