import React, { forwardRef } from 'react'
import MaterialTable from 'material-table'
import { Link } from 'react-router-dom';
import _ from 'lodash'
import { ConvetDateToDDMMYYHHMM } from '../../../helpers/common'
import ColumnTypes from '../../../constants/view/columnTypes.json'
import Field from '../field'
import { messages } from '../../../translations/messages'
import { injectIntl } from "react-intl"
import Button from '@material-ui/core/Button';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

class DataTable extends React.Component {
    renderCustomColumn(value, type) {
        const { intl } = this.props;
        let columnValue = value;
        if (type === ColumnTypes.DATE) {
            columnValue = ConvetDateToDDMMYYHHMM(value, '.');
        } else if (type === ColumnTypes.IMAGE) {
            columnValue = <img src={value} alt="list-i" width={50} />
        } else if (type === ColumnTypes.STATUS) {
            columnValue = <Field value={value > 0 ? intl.formatMessage(messages.ACTIVE) : intl.formatMessage(messages.PASSIVE)} coloredText={value ? "SUCCESS" : "WARNING"} />
        } else if (type === ColumnTypes.BOOL) {
            columnValue = <Field value={value > 0 ? intl.formatMessage(messages.YES) : intl.formatMessage(messages.NO)} coloredText={value ? "SUCCESS" : "DANGER"} />
        }
        return columnValue;
    }
    customizedColumns() {
        const { data } = this.props;
        const { columns, editPageUrl,checkCargoStatus } = data;
        let _columns = columns;
        let _checkCargoStatus=checkCargoStatus;
        if(_checkCargoStatus){
            for (let item of _columns) {
                item["render"] = rowData => (item.customRender ? <div>{item.customRender(rowData)}</div> : <Link to={{ pathname:rowData.cargoStatusID==4?editPageUrl.replace('0', rowData.id):"" }}>{this.renderCustomColumn(_.get(rowData, item.field), item.columnType)}</Link>);
            }
        }else{
            for (let item of _columns) {
                item["render"] = rowData => (item.customRender ? <div>{item.customRender(rowData)}</div> : <Link to={{ pathname:editPageUrl.replace('0', rowData.id) }}>{this.renderCustomColumn(_.get(rowData, item.field), item.columnType)}</Link>);
            }
        }
      
        return _columns;
    }
    componentDidMount() {
        const { data } = this.props;
        const { getList, customParameters } = data;
        if (customParameters)
            getList(customParameters, data.listModel.filterModel);
        else
            getList(data.listModel.filterModel)

    }
    render() {
        const { data } = this.props;
        const { listModel } = data;
        return (
            <div className="data_table_container">
                <MaterialTable
                    icons={tableIcons}
                    columns={this.customizedColumns()}
                    data={listModel.data}
                    isLoading={listModel.loading}
                    onChangePage={(page) => {
                    }}
                    options={
                        {
                            maxBodyHeight: 700,
                            headerStyle: {
                                borderBottom: "1px solid",
                                fontWeight: 600,
                                position: 'sticky',
                                top: 0
                            },
                            filtering:true,
                            pageSize: 25,
                            pageSizeOptions: [
                                25,
                                50,
                                100
                            ],
                            search: false,
                            toolbar: false,
                            rowStyle: x => {
                                if (x.tableData.id % 2) {
                                    return { backgroundColor: "rgba(0,0,0,.03)" }
                                }
                            }
                        }}
                    localization={
                        {
                            body: {
                                emptyDataSourceMessage: "Gösterilecek kayıt yok"
                            },
                            toolbar: {
                                searchTooltip: "Arama"
                            },
                            pagination: {
                                labelRowsSelect: "satır",
                                labelDisplayedRows: "{count} satırdan {from}-{to} arası",
                                firstTooltip: "İlk Sayfa",
                                previousTooltip: "Önceki Sayfa",
                                nextTooltip: "Sonraki Sayfa",
                                lastTooltip: "Son Sayfa"
                            }
                        }
                    }
                />
            </div>
        );
    }
}

export default injectIntl(DataTable)