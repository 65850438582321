import { _getLocationInfo, _updateOrderItem, _deleteOrderItem } from '../../../../services/catalog/barcode';
import { ShowSnackBar, ToggleLoader } from '../../view'

export const UPDATE_BARCODE = 'UPDATE_BARCODE';
export const DELETE_BARCODE = 'DELETE_BARCODE';
export const SET_INITIAL_BARCODE = "SET_INITIAL_BARCODE";

export const getObject = (id) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(ToggleLoader(true));
        _getLocationInfo(id).then(response => {
            dispatch(ToggleLoader(false));
            dispatch({
                type: UPDATE_BARCODE,
                payload: response
            });
            resolve(response)
        }).catch(message => {
            dispatch(ToggleLoader(false));
            dispatch(ShowSnackBar('error', message, 10000));
            reject(message);
        });
    });
};

export const updateObject = (requestModel) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(ToggleLoader(true));
        _updateOrderItem(requestModel).then(response => {
            dispatch(ToggleLoader(false));
            dispatch({
                type: UPDATE_BARCODE,
                payload: response
            });
            dispatch(ShowSnackBar('success', "District Plate Updated.", 10000))
            resolve(response)
        }).catch(message => {
            dispatch(ToggleLoader(false));
            dispatch(ShowSnackBar('error', message, 10000));
            reject(message);
        });
    });
};

export const deleteObject = (id) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(ToggleLoader(true));
        _deleteOrderItem(id).then(response => {
            dispatch(ToggleLoader(false));
            dispatch({
                type: DELETE_BARCODE,
                payload: response
            });
            dispatch(ShowSnackBar('success', "District Plate Deleted.", 10000))
            resolve(response);
        }).catch(message => {
            dispatch(ToggleLoader(false));
            dispatch(ShowSnackBar('error', message, 10000));
            reject(message);
        });
    });
};

export const setInitialState = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: SET_INITIAL_BARCODE,
            payload: true
        });
        resolve(1);
    });
};