import React from 'react'
import ListPage from '../../../components/views/pages/listPage'
import ButtonTypes from '../../../constants/view/buttonTypes.json'
import ColumnTypes from '../../../constants/view/columnTypes.json'
import { messages } from '../../../translations/messages'
import { injectIntl } from "react-intl"
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { getList } from '../../../redux/actions/catalog/list/consignment_actions'

const EDIT_PAGE_URL = "/catalog/barcodes/list/0";

class ConsignmentList extends React.Component {
    render() {
        const { intl } = this.props;
        console.log(this.props)
        return (
            <div id="consignment_list">
                <ListPage
                    breadCrumbData={
                        {
                            title: intl.formatMessage(messages.ALL_CONSIGNMENTS),
                            items: [
                                {
                                    title: intl.formatMessage(messages.DASHBOARD),
                                    link: "/"
                                },
                                {
                                    title: intl.formatMessage(messages.CONSIGNMENTS)
                                }
                            ]
                        }
                    }
                    buttonsData={
                        [
                            {
                                type: ButtonTypes.FILTER,
                                label: intl.formatMessage(messages.FILTER)
                            }
                        ]
                    }
                    dataTable={
                        {
                            getList: this.props.onGetList,
                            listModel: this.props.listModel,
                            editPageUrl: EDIT_PAGE_URL,
                            columns: [
                                { title: intl.formatMessage(messages.ID), field: 'id', },
                                { title: intl.formatMessage(messages.ORDER_NUMBER), field: 'orderNo' },
                                { title: intl.formatMessage(messages.COMPANY_NAME), field: 'company.name' },
                                { title: intl.formatMessage(messages.CURRENT_CODE), field: 'company.currentNo' },
                                { title: intl.formatMessage(messages.REGION_CODE), field: 'regionCode', },
                                { title: intl.formatMessage(messages.CREATED_DATE),type:'date',  field: 'dateCreated', columnType: ColumnTypes.DATE },
                                { title: intl.formatMessage(messages.QUANTITY), field: 'quantity' },
                                { title: intl.formatMessage(messages.IS_DELIVERED), field: 'cargoStatus.name' }
                            ]
                        }
                    }
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        listModel: state.listConsignmentReducer.listModel
    };
}

const mapDispatchToProps = {
    onGetList: getList,
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(ConsignmentList)));