import { postService, getService } from '../index'
import { getServiceErrorMessage, handleServiceResult } from '../../helpers/services'
import { setLocalStorage, getLocalStorage } from '../../helpers/common/localStorage'
import { setAuthorizationKey } from '../../helpers/account/Authorization';

//#region getServices
export const _getProfile = (id = 0) => new Promise((resolve, reject) => {
    let qs = "";
    if (id > 0)
        qs = "?userId" + id;

    getService('/api/User/getprofile' + qs).then(response => {
        const _handleServiceResult = handleServiceResult(response);
        if (_handleServiceResult.success) {
            resolve(response.data);
        } else {
            reject(getServiceErrorMessage(response));
        }
    }).catch(error => {
        reject(getServiceErrorMessage(error.response));
    })
});
//#endregion

//#region postServices
export const _refreshToken = () => new Promise((resolve, reject) => {
    var token = getLocalStorage('refreshToken');
    if (!token) {
        reject('refresh_token_not_found');
        return;
    }
    postService('/api/Auth/refreshtoken?Token=' + token).then(response => {
        const _handleServiceResult = handleServiceResult(response);
        if (_handleServiceResult.success) {
            setLocalStorage('refreshToken', response.data.refreshToken);
            setLocalStorage('expiration', response.data.expiration);
            setAuthorizationKey(response.data.token);
            resolve(response.data);
        } else {
            reject(response);
        }
    }).catch(error => {
        reject(error);
    })
});

export const _register = (requestModel) => new Promise((resolve, reject) => {
    postService('/api/Auth/register', requestModel).then(response => {
        const _handleServiceResult = handleServiceResult(response);
        if (_handleServiceResult.success) {
            setLocalStorage('refreshToken', response.data.refreshToken);
            setLocalStorage('expiration', response.data.expiration);
            setAuthorizationKey(response.data.token);
            resolve(response.data);
        } else {
            reject(getServiceErrorMessage(response));
        }
    }).catch(error => {
        reject(getServiceErrorMessage(error.response));
    })
});

export const _login = (username, password) => new Promise((resolve, reject) => {
    postService('/api/Auth/login', { username, password }).then(response => {
        const _handleServiceResult = handleServiceResult(response);
        if (_handleServiceResult.success) {
            setLocalStorage('refreshToken', response.data.refreshToken);
            setLocalStorage('expiration', response.data.expiration);
            setAuthorizationKey(response.data.token);
            resolve(response.data);
        } else {
            reject(getServiceErrorMessage(response));
        }
    }).catch(error => {
        reject(getServiceErrorMessage(error.response));
    })
});

export const _generateForgetPasswordToken = (userName) => new Promise((resolve, reject) => {
    postService('/api/ForgetPassword/generateforgetpasswordtoken', {
        userName
    }).then(response => {
        const _handleServiceResult = handleServiceResult(response);
        if (_handleServiceResult.success) {
            resolve(response.data);
        } else {
            reject(getServiceErrorMessage(response));
        }
    }).catch(error => {
        reject(getServiceErrorMessage(error.response));
    })
});

export const _resetPassword = (token, newPassword) => new Promise((resolve, reject) => {
    postService('/api/ForgetPassword/resetpassword', {
        token,
        newPassword
    }).then(response => {
        const _handleServiceResult = handleServiceResult(response);
        if (_handleServiceResult.success) {
            resolve(response.data);
        } else {
            reject(getServiceErrorMessage(response));
        }
    }).catch(error => {
        reject(getServiceErrorMessage(error.response));
    })
});

export const _editProfile = (requestModel) => new Promise((resolve, reject) => {
    postService('/api/CompanyInformation/update', requestModel).then(response => {
        const _handleServiceResult = handleServiceResult(response);
        if (_handleServiceResult.success) {
            resolve(response.data);
        } else {
            reject(getServiceErrorMessage(response));
        }
    }).catch(error => {
        reject(getServiceErrorMessage(error.response));
    })
});

export const _changePassword = (requestModel) => new Promise((resolve, reject) => {
    postService('/api/User/changepassword', requestModel).then(response => {
        const _handleServiceResult = handleServiceResult(response);
        if (_handleServiceResult.success) {
            resolve(response.data);
        } else {
            reject(getServiceErrorMessage(response));
        }
    }).catch(error => {
        reject(getServiceErrorMessage(error.response));
    })
});
//#endregion

//#region deleteServices

//#endregion