import { defineMessages } from "react-intl";

export const messages = defineMessages({
  DASHBOARD: { id: 'DASHBOARD' },
  LOGIN_TEXT: { id: 'LOGIN_TEXT' },
  USER_NAME: { id: 'USER_NAME' },
  PASSWORD: { id: 'PASSWORD' },
  USERNAME_CAN_NOT_BE_BLANK: { id: 'USERNAME_CAN_NOT_BE_BLANK' },
  PASSWORD_CAN_NOT_BE_BLANK: { id: 'PASSWORD_CAN_NOT_BE_BLANK' },
  REMEMBER_ME: { id: 'REMEMBER_ME' },
  FORGOT_PASSWORD: { id: 'FORGOT_PASSWORD' },
  LOGIN: { id: 'LOGIN' },
  NOT_A_MEMBER: { id: 'NOT_A_MEMBER' },
  SIGN_UP_NOW: { id: 'SIGN_UP_NOW' },
  NAME: { id: 'NAME' },
  DESCRIPTON: { id: 'DESCRIPTON' },
  SERVICE_USER_NAME: { id: 'SERVICE_USER_NAME' },
  SERVICE_PASSWORD: { id: 'SERVICE_PASSWORD' },
  DIRECTION: { id: 'DIRECTION' },
  CODE: { id: 'CODE' },
  LANGUAGE: { id: 'LANGUAGE' },
  USER: { id: 'USER' },
  USERS: { id: 'USERS' },
  FIRM: { id: 'FIRM' },
  STATUS: { id: 'STATUS' },
  CANCEL: { id: 'CANCEL' },
  SAVE: { id: 'SAVE' },
  DELETE: { id: 'DELETE' },
  CONFIRM: { id: 'CONFIRM' },
  ALL_APPLICATION_CLIENT: { id: 'ALL_APPLICATION_CLIENT' },
  NEW_APPLICATION_CLIENT: { id: 'NEW_APPLICATION_CLIENT' },
  EDIT_APPLICATION_CLIENT: { id: 'EDIT_APPLICATION_CLIENT' },
  APPLICATION_CLIENTS: { id: 'APPLICATION_CLIENTS' },
  APPLICATION_CLIENT: { id: 'APPLICATION_CLIENT' },
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_APPLICATION_CLIENT_QUESTION: { id: 'ARE_YOU_SURE_YOU_WANT_TO_DELETE_APPLICATION_CLIENT_QUESTION' },
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_CATEGORY_QUESTION: { id: 'ARE_YOU_SURE_YOU_WANT_TO_DELETE_CATEGORY_QUESTION' },
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_DEPARTMENT_QUESTION: { id: 'ARE_YOU_SURE_YOU_WANT_TO_DELETE_DEPARTMAN_QUESTION' },
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_FIRM_QUESTION: { id: 'ARE_YOU_SURE_YOU_WANT_TO_DELETE_FIRM_QUESTION' },
  EXTRA_INFORMATIONS: { id: 'EXTRA_INFORMATIONS' },
  FILTER: { id: 'FILTER' },
  ADDRESS: { id: 'ADDRESS' },
  ALL_CATEGORIES: { id: 'ALL_CATEGORIES' },
  CATEGORY: { id: 'CATEGORY' },
  NEW_CATEGORY: { id: 'NEW_CATEGORY' },
  PARENT_CATEGORY: { id: 'PARENT_CATEGORY' },
  EDIT_CATEGORY: { id: 'EDIT_CATEGORY' },
  ALL_DEPARTMENTS: { id: 'ALL_DEPARTMENTS' },
  DEPARTMENT: { id: 'DEPARTMENT' },
  NEW_DEPARTMENT: { id: 'NEW_DEPARTMENT' },
  EDIT_DEPARTMENT: { id: 'EDIT_DEPARTMENT' },
  ALL_FIRMS: { id: 'ALL_FIRMS' },
  NEW_FIRM: { id: 'NEW_FIRM' },
  EDIT_FIRM: { id: 'EDIT_FIRM' },
  FIRMTYPE: { id: 'FIRMTYPE' },
  ALL_COMPANIES: { id: 'ALL_COMPANIES' },
  ALL_USERS: { id: 'ALL_USERS' },
  COMPANIES: { id: 'COMPANIES' },
  NEW_COMPANY: { id: 'NEW_COMPANY' },
  ALL_CONSIGNMENTS: { id: 'ALL_CONSIGNMENTS' },
  CONSIGNMENTS: { id: 'CONSIGNMENTS' },
  NEW_CONSIGNMENT: { id: 'NEW_CONSIGNMENT' },
  ALL_DISTRICT_PLATE: { id: 'ALL_DISTRICT_PLATE' },
  DISTRICT_PLATES: { id: 'DISTRICT_PLATES' },
  NEW_DISTRICT_PLATE: { id: 'NEW_DISTRICT_PLATE' },
  EDIT_DISTRICT_PLATE: { id: 'EDIT_DISTRICT_PLATE' },
  DISTRICT_PLATE: { id: 'DISTRICT_PLATE' },
  PLATE: { id: 'PLATE' },
  COURIER_CODE: { id: 'COURIER_CODE' },
  COURIER_NAME: { id: 'COURIER_NAME' },
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_DISTRICT_PLATE_QUESTION: { id: 'ARE_YOU_SURE_YOU_WANT_TO_DELETE_DISTRICT_PLATE_QUESTION' },
  CONFIGURATION: { id: 'CONFIGURATION' },
  CHANGE_LANGUAGE: { id: 'CHANGE_LANGUAGE' },
  CATALOG: { id: 'CATALOG' },
  VISA: { id: 'VISA' },
  COMPANY_LIST: { id: 'COMPANY_LIST' },
  DISTRICT_PLATE_LIST: { id: 'DISTRICT_PLATE_LIST' },
  CONSIGNMENT_LIST: { id: 'CONSIGNMENT_LIST' },
  ID: { id: 'ID' },
  INVOICE_NUMBER: { id: 'INVOICE_NUMBER' },
  BARCODE: { id: 'BARCODE' },
  BARCODES: { id: 'BARCODES' },
  SUB_BARCODE: { id: 'SUB_BARCODE' },
  CREATED_DATE: { id: 'CREATED_DATE' },
  RECEIVED_COMPANY: { id: 'RECEIVED_COMPANY' },
  RECEIVED_USER_NAME: { id: 'RECEIVED_USER_NAME' },
  RECEIVED_DATE: { id: 'RECEIVED_DATE' },
  CURRENT_CODE: { id: 'CURRENT_CODE' },
  COMPANY_NAME: { id: 'COMPANY_NAME' },
  COUNTRY: { id: 'COUNTRY' },
  CITY: { id: 'CITY' },
  PHONE_NUMBER: { id: 'PHONE_NUMBER' },
  ORDER_NUMBER: { id: 'ORDER_NUMBER' },
  INVOICE_DATE: { id: 'INVOICE_DATE' },
  QUANTITY: { id: 'QUANTITY' },
  COLLECTED_COUNT: { id: 'COLLECTED_COUNT' },
  IS_DELIVERED: { id: 'IS_DELIVERED' },
  IS_APPROVED: { id: 'IS_APPROVED' },
  ACTIVE: { id: 'ACTIVE' },
  PASSIVE: { id: 'PASSIVE' },
  YES: { id: 'YES' },
  NO: { id: 'NO' },
  LOCATION_INFO: { id: 'LOCATION_INFO' },
  SIGNAL_TIME: { id: 'SIGNAL_TIME' },
  REGION_CODE: { id: 'REGION_CODE' },
  FİRST_NAME: { id: 'FİRST_NAME' },
  LAST_NAME: { id: 'LAST_NAME' },
  PENDING_APPROVAL_USERS: { id: 'PENDING_APPROVAL_USERS' },
  APPROVE:{id:'APPROVE'},
  EMAIL:{id:'EMAIL'}

});