import React from 'react'

class DrawerContainer extends React.Component {
    render() {
        const { title } = this.props; 
        return (
            <div className="drawer_container">
                <div className="title_area">
                    <h2>{title}</h2>
                </div>
                <div className="body_area">
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default DrawerContainer;