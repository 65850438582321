import React from 'react'
import Paper from '@material-ui/core/Paper'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'

const CardWithLastMonthPercentage = ({ data }) => {
    const { title, value, icon, color } = data;
    return (
        <Paper variant="outlined" elevation={1}>
            <div className="card_item card_with_last_month_percentage">
                <div className="top">
                    <div className="area">
                        <div className="title">
                            <span>{title}</span>
                        </div>
                        <div className="value_item">
                            <span>{value}</span>
                        </div>
                    </div>
                    <div className="area">
                        <div className="icon_item" style={{ backgroundColor: color }}>
                            {icon}
                        </div>
                    </div>
                </div>
                <div className="bottom">
                    {data.extraData ?
                        <div className="extra_data_area">
                            <p> <span className={(data.extraData.rise ? "green" : "red")}>{(data.extraData.rise ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />)} {data.extraData.value}</span> Since last month </p>
                        </div>
                        : null}
                </div>
            </div>
        </Paper>
    )
}

export default CardWithLastMonthPercentage;