import { _login, _register, _generateForgetPasswordToken, _resetPassword } from '../../../services/account'
import { ShowSnackBar, ToggleLoader } from '../view'
import { RemoveAuthorization, getAuthorizationKey } from '../../../helpers/account/Authorization'
import jwt_decode from 'jwt-decode'

export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGIN_USER = 'LOGIN_USER';
export const REGISTER_USER = 'REGISTER_USER';
export const BEGIN_PASSWORD_RESET = 'BEGIN_PASSWORD_RESET';
export const RESET_PASSWORD = 'RESET_PASSWORD';

export const register = (request) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(ToggleLoader(true));
        _register(request).then(response => {
            dispatch(ToggleLoader(false));
            dispatch({
                type: LOGIN_USER,
                payload: true
            });
            resolve(response.data);
        }).catch(message => {
            dispatch(ToggleLoader(false));
            dispatch(ShowSnackBar('error', message, 10000));
            reject('err');
        });
    });
};

export const LoginUser = (username, password) => {
    return dispatch => {
        dispatch(ToggleLoader(true));
        _login(username, password).then(response => {
            dispatch(ToggleLoader(false));
            dispatch({
                type: LOGIN_USER,
                payload: true
            });
        }).catch(message => {
            dispatch(ToggleLoader(false));
            dispatch(ShowSnackBar('error', message, 10000));
        });
    }
}

export const GenerateForgetPasswordToken = (username) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(ToggleLoader(true));
        _generateForgetPasswordToken(username).then(response => {
            dispatch(ToggleLoader(false));
            dispatch({
                type: BEGIN_PASSWORD_RESET,
                payload: true
            });
            resolve('ok');
        }).catch(message => {
            dispatch(ToggleLoader(false));
            dispatch(ShowSnackBar('error', message, 10000));
            reject('error')
        });
    })
}

export const ResetPassword = (token, newPassword) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(ToggleLoader(true));
        _resetPassword(token, newPassword).then(response => {
            dispatch(ToggleLoader(false));
            dispatch({
                type: RESET_PASSWORD,
                payload: true
            });
            resolve('ok');
        }).catch(message => {
            dispatch(ToggleLoader(false));
            dispatch(ShowSnackBar('error', message, 10000));
            reject('err');
        });
    })
}

export const checkAuth = () => (dispatch) => {
    const token = getAuthorizationKey();
    if (token) {
        const dateNow = new Date();
        const { exp } = jwt_decode(token);
        if (exp < (dateNow.getTime() / 1000)) {
            RemoveAuthorization();
            dispatch({
                type: LOGOUT_USER,
                payload: false
            });
            return false;
        } else
            return true;
    } else
        return false;
}

export const LogoutUser = () => {
    return dispatch => {
        RemoveAuthorization();
        dispatch({
            type: LOGOUT_USER,
            payload: false
        });
    }
}



