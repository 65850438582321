import React from 'react'
import { Paper } from '@material-ui/core'
import ButtonTypes from '../../../../constants/view/buttonTypes.json'
import Buttons from '../buttons'
import { ConfirmDialog } from '../../../common'
import { messages } from '../../../../translations/messages'
import { useIntl } from "react-intl"

const FormTemplate = ({ title, onSave, children, deleteDialogData }) => {
    const intl = useIntl();
    const buttonsData = () => {
        let data = [];

        if (deleteDialogData) {
            data.push(
                {
                    type: ButtonTypes.CUSTOM,
                    customContent: <ConfirmDialog
                        title={deleteDialogData.title}
                        body={deleteDialogData.body}
                        confirmAction={deleteDialogData.confirmAction} />
                }
            );
        }

        if (onSave) {
            data.push(
                {
                    type: ButtonTypes.SAVE,
                    label: intl.formatMessage(messages.SAVE),
                    action: onSave,
                    variant: "contained",
                    color: "primary"
                }
            );
        }

        return data;
    }
    const _buttonsData = buttonsData();
    return (
        <div className="form_template_container">
            <Paper variant="outlined" elevation={1}>
                {title ?
                    <div className="title_area">
                        <h3>{title}</h3>
                    </div> : null}
                <div className="body_area">
                    {children}
                </div>
                {_buttonsData && _buttonsData.length > 0 ?
                    <div className="buttons_area">
                        <Buttons data={_buttonsData} />
                    </div> : null}
            </Paper>
        </div>
    );
}

export default FormTemplate;