import React from 'react'
import ListPage from '../../../components/views/pages/listPage'
import ButtonTypes from '../../../constants/view/buttonTypes.json'
import { messages } from '../../../translations/messages'
import { injectIntl } from "react-intl"
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { getList } from '../../../redux/actions/visa/list/company_actions'

const EDIT_PAGE_URL = "";

class CompanyList extends React.Component {
    render() {
        const { intl } = this.props;
        return (
            <div id="company_list">
                <ListPage
                    breadCrumbData={
                        {
                            title: intl.formatMessage(messages.ALL_COMPANIES),
                            items: [
                                {
                                    title: intl.formatMessage(messages.DASHBOARD),
                                    link: "/"
                                },
                                {
                                    title: intl.formatMessage(messages.COMPANIES)
                                }
                            ]
                        }
                    }
                    buttonsData={
                        [
                            {
                                type: ButtonTypes.FILTER,
                                label: intl.formatMessage(messages.FILTER)
                            }
                        ]
                    }
                    dataTable={
                        {
                            getList: this.props.onGetList,
                            listModel: this.props.listModel,
                            editPageUrl: EDIT_PAGE_URL,
                            columns: [
                                { title: intl.formatMessage(messages.ID), field: 'id', },
                                { title: intl.formatMessage(messages.CURRENT_CODE), field: 'currentNo' },
                                { title: intl.formatMessage(messages.COMPANY_NAME), field: 'name' },
                                { title: intl.formatMessage(messages.COUNTRY), field: 'country' },
                                { title: intl.formatMessage(messages.CITY), field: 'city' },
                                { title: intl.formatMessage(messages.PHONE_NUMBER), field: 'workPhoneNumber' }
                            ]
                        }
                    }
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        listModel: state.listCompanyReducer.listModel
    };
}

const mapDispatchToProps = {
    onGetList: getList,
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(CompanyList)));