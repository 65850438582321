import React from 'react'
import NotFoundImage from '../../assets/images/not_found.svg'
import Typography from '@material-ui/core/Typography';

class NotFound extends React.Component {
    render() {
        return (
            <div className="not_found error_page">
                <Typography variant="h3" gutterBottom>
                    404: The page you are looking for isn’t here
                </Typography>
                <Typography variant="h6" gutterBottom>
                    You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation
                </Typography>
                <img src={NotFoundImage} alt="error" />
            </div>
        )
    }
}

export default NotFound