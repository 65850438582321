import {
    UPDATE_DISTRICT_PLATE,
    SET_INITIAL_DISTRICT_PLATE
} from '../../../actions/configuration/edit/district_plate_actions'
import { copyObjectWithControl } from '../../../../helpers/common/objectArray'

const initialState = {
    model: {
        id: 0,
        name: "",
        code: "",
        plate: "",
        courierCode: "",
        courierName: "",
        statusID: 0
    },
    requestModel: {
        id: 0,
        name: "",
        code: "",
        plate: "",
        courierCode: "",
        courierName: "",
        statusID: 0
    }
};

function reducer(state = initialState, { type, payload }) {
    switch (type) {
        case SET_INITIAL_DISTRICT_PLATE:
            return { ...initialState }
        case UPDATE_DISTRICT_PLATE:
            return { ...state, model: { ...copyObjectWithControl(state.model, payload) } }
        default:
            return { ...state };
    }
}

export default reducer;