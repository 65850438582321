import React from 'react'
import BreadCrumb from '../breadcrumb'
import Buttons from '../buttons'
import { Grid, Paper } from '@material-ui/core'

class EditPage extends React.Component {
    render() {
        const { breadCrumbData, buttonsData, children, paper } = this.props;
        return (
            <div className="edit_page_template_container">
                <div className="top_area">
                    <Grid container spacing={2}>
                        <Grid item md={6}>
                            <div className="left_area">
                                <BreadCrumb data={breadCrumbData} />
                            </div>
                        </Grid>
                        <Grid item md={6}>
                            <div className="right_area">
                                <Buttons data={buttonsData} />
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div className="bottom_area">
                    {paper ?
                        <Paper variant="outlined" elevation={1}>
                            <div className="form_container">
                                {children}
                            </div>
                        </Paper> :
                        <div className="form_container">
                            {children}
                        </div>}
                </div>
            </div>
        )
    }
}

export default EditPage;