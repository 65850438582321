import React from 'react';
import TextField from '@material-ui/core/TextField'
import NumberFormat from 'react-number-format'
import PropTypes from 'prop-types'

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
        />
    );
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default function textFieldComponent({ id, label, placeholder, error, helperText, value, onChange, type, disabled, style, multiline, rows, rowsMax, className, onKeyDown, numberFormat }) {

    return (
        <TextField
            id={id}
            onKeyDown={onKeyDown}
            className={className}
            label={label}
            disabled={disabled}
            style={style}
            type={type}
            error={error}
            multiline={multiline}
            rows={rows}
            helperText={helperText}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            fullWidth
            margin="normal"
            InputProps={
                numberFormat ? {
                    inputComponent: NumberFormatCustom
                } : null
            }
            InputLabelProps={{
                shrink: true,
            }}
            variant="outlined"
        />
    );
}