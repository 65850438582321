const getWindowDimensions = () => {
    const { outerWidth: width, outerHeight: height } = window;
    return {
        width,
        height
    }
}

const isReponsiveMode = () => {
    const { width } = getWindowDimensions();
    return width < 768;
}

export { getWindowDimensions, isReponsiveMode }