import React from 'react'
import Paper from '@material-ui/core/Paper'

const Card = ({ data }) => {
    const { title, value, icon, color } = data;
    return (
        <Paper variant="outlined" elevation={1}>
            <div className="card_item card_with_last_month_percentage">
                <div className="top">
                    <div className="area">
                        <div className="title">
                            <span>{title}</span>
                        </div>
                        <div className="value_item">
                            <span>{value}</span>
                        </div>
                    </div>
                    <div className="area">
                        <div className="icon_item" style={{ backgroundColor: color }}>
                            {icon}
                        </div>
                    </div>
                </div>
                <div className="bottom">
                </div>
            </div>
        </Paper>
    )
}

export default Card;