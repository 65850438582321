import React from 'react'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 12,
        borderRadius: 15,
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
        borderRadius: 5
    },
}))(LinearProgress);

const CardWithProgress = ({ data }) => {
    const { title, value, icon, color } = data;
    return (
        <Paper variant="outlined" elevation={1}>
            <div className="card_item card_with_percentage">
                <div className="top">
                    <div className="area">
                        <div className="title">
                            <span>{title}</span>
                        </div>
                        <div className="value_item">
                            <span>{value}</span>
                        </div>
                    </div>
                    <div className="area">
                        <div className="icon_item" style={{ backgroundColor: color }}>
                            {icon}
                        </div>
                    </div>
                </div>
                <div className="bottom">
                    {data.extraData ?
                        <div className="extra_data_area">
                            <BorderLinearProgress variant="determinate" value={parseInt(data.extraData.value)} />
                        </div>
                        : null}
                </div>
            </div>
        </Paper>
    )
}

export default CardWithProgress;