import MainDashboard from '../views/dashboards/main_dashboard'
import DistrictPlateList from '../views/configuration/list/districtPlate'
import DistrictPlateEdit from '../views/configuration/edit/districtPlate'
import CompanyList from '../views/visa/list/company'
import UserList from '../views/visa/list/user'
import PendingApprovalUserList from '../views/visa/list/pending_approval_user'
import ConsignmentList from '../views/catalog/list/consignment'
import BarcodeList from '../views/catalog/list/barcode'
import BarcodeEdit from '../views/catalog/edit/barcode'

const privateRoutes = {
    Home: {
        component: MainDashboard,
        path: '/'
    },
    MainDashboard: {
        component: MainDashboard,
        path: '/main-dashboard'
    },
    DistrictPlateList: {
        component: DistrictPlateList,
        path: '/configuration/district-plate/list'
    },
    DistrictPlateEdit: {
        component: DistrictPlateEdit,
        path: '/configuration/district-plate/edit/:id'
    },
    CompanyList: {
        component: CompanyList,
        path: '/visa/company/list'
    },
    UserList: {
        component: UserList,
        path: '/visa/user/list'
    },
    PendingApprovalUserList: {
        component: PendingApprovalUserList,
        path: '/visa/pendingapprovaluser/list'
    },
    ConsignmentList: {
        component: ConsignmentList,
        path: '/catalog/consignment/list'
    },
    BarcodeList: {
        component: BarcodeList,
        path: '/catalog/barcodes/list/:id'
    },
    BarcodeEdit:{
        component:BarcodeEdit,
        path: '/catalog/barcodes/edit/:id'
    }
};

export default privateRoutes