import React from 'react'
import EditPage from '../../../components/views/pages/editPage'
import Form from '../../../components/views/pages/form'
import ButtonTypes from '../../../constants/view/buttonTypes.json'
import { Grid } from '@material-ui/core'
import { TextField, SelectBox, Switch } from '../../../components/common'
import { mergeObject } from '../../../helpers/common/objectArray';
import { messages } from '../../../translations/messages'
import { injectIntl } from "react-intl"
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import Services from '../../../constants/services/list_services.json'

import { getObject, updateObject, deleteObject, setInitialState } from '../../../redux/actions/configuration/edit/district_plate_actions'
import { ShowSnackBar } from '../../../redux/actions/view'
import ExtraInformations from '../../../components/views/pages/editPage/extraInformations'

const LIST_PAGE_URL = "/configuration/district-plate/list";

class EditDistrictPlate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            model: this.props.model
        }
        this.onSave = this.onSave.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }
    fillState(key, value) {
        let dataModel = this.state.model;
        dataModel[key] = value;
        this.setState({ model: { ...dataModel } });
    }
    propsToState() {
        const { model } = this.props;
        this.setState({
            model: model
        });
    }
    getId() {
        return this.props.route.match.params.id
    }
    renderExtraDefinitions() {
        return this.getId() > 0;
    }
    returnToListPage() {
        this.props.history.push(LIST_PAGE_URL);
    }
    requestModel() {
        let model = mergeObject(this.props.requestModel, this.state.model);
        return model;
    }
    validateModel() {
        let id = this.getId();
        var message = "";

        const { code, courierCode, plate } = this.state.model;

        if (!code) {
            message = "code";
        } else if (!courierCode) {
            message = "courierCode";
        } else if (!plate) {
            message = "plate";
        }

        if (message) {
            this.props.onShowSnackBar('error', message);
        } else {
            this.setState({ id: id });
        }
        return message ? false : true;
    }
    componentDidMount() {
        const id = this.getId();
        this.props.onSetInitialState().then(resp => this.propsToState());
        if (id > 0)
            this.props.onGetObject(id)
                .then(response => this.propsToState())
                .catch(err => this.returnToListPage())
    }
    onSave() {
        var formIsValid = this.validateModel();
        if (formIsValid) {
            const requestModel = this.requestModel();
            this.props.onUpdateObject(requestModel).then(res => {
                this.returnToListPage();
            });
        }
    }
    onDelete() {
        const id = this.getId();
        if (id > 0)
            this.props.onDeleteObject(id).then(response => this.returnToListPage())
    }
    render() {
        const { intl } = this.props;

        return (
            <div id="district_plate_edit">
                <EditPage
                    breadCrumbData={
                        {
                            title: intl.formatMessage(messages.EDIT_DISTRICT_PLATE),
                            items: [
                                {
                                    title: intl.formatMessage(messages.DASHBOARD),
                                    link: "/"
                                },
                                {
                                    title: intl.formatMessage(messages.DISTRICT_PLATES),
                                    link: LIST_PAGE_URL
                                },
                                {
                                    title: intl.formatMessage(messages.EDIT_DISTRICT_PLATE)
                                }
                            ]
                        }
                    }
                    buttonsData={
                        [
                            {
                                type: ButtonTypes.GO_BACK
                            }
                        ]
                    }>
                    <Grid container spacing={5}>
                        <Grid item md={(this.renderExtraDefinitions() ? 9 : 12)}>
                            <Form
                                title={intl.formatMessage(messages.DISTRICT_PLATE)}
                                onSave={this.onSave}
                                deleteDialogData={
                                    {
                                        title: intl.formatMessage(messages.DISTRICT_PLATE),
                                        body: intl.formatMessage(messages.ARE_YOU_SURE_YOU_WANT_TO_DELETE_DISTRICT_PLATE_QUESTION),
                                        confirmAction: this.onDelete
                                    }
                                }>
                                <Grid container spacing={2}>
                                    <Grid item md={6}>
                                        <TextField
                                            disabled={this.props.disabled}
                                            label={intl.formatMessage(messages.CODE)}
                                            placeholder={intl.formatMessage(messages.CODE)}
                                            value={this.state.model.code}
                                            onChange={(e) => this.fillState("code", e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item md={6}>
                                        <TextField
                                            disabled={this.props.disabled}
                                            label={intl.formatMessage(messages.PLATE)}
                                            placeholder={intl.formatMessage(messages.PLATE)}
                                            value={this.state.model.plate}
                                            onChange={(e) => this.fillState("plate", e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item md={6}>
                                        <TextField
                                            disabled={this.props.disabled}
                                            label={intl.formatMessage(messages.COURIER_CODE)}
                                            placeholder={intl.formatMessage(messages.COURIER_CODE)}
                                            value={this.state.model.courierCode}
                                            onChange={(e) => this.fillState("courierCode", e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item md={6}>
                                        <TextField
                                            disabled={this.props.disabled}
                                            label={intl.formatMessage(messages.COURIER_NAME)}
                                            placeholder={intl.formatMessage(messages.COURIER_NAME)}
                                            value={this.state.model.courierName}
                                            onChange={(e) => this.fillState("courierName", e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item md={6}>
                                        <Switch
                                            margin="0px 5px"
                                            disabled={this.props.disabled}
                                            label={intl.formatMessage(messages.STATUS)}
                                            labelPlacement="end"
                                            value={this.state.model.statusID}
                                            onChange={(e) => this.fillState("statusID", e.target.checked)}
                                        />
                                    </Grid>
                                </Grid>
                            </Form>
                        </Grid>
                        {
                            this.renderExtraDefinitions() ?
                                <Grid item md={3}>
                                    <ExtraInformations data={this.state.model} />
                                </Grid> : null
                        }
                    </Grid>
                </EditPage>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        model: state.editDistrictPlateReducer.model,
        requestModel: state.editDistrictPlateReducer.requestModel,
        loading: state.viewReducer.loader.showLoader,
        disabled: state.viewReducer.loader.disabled
    };
}

const mapDispatchToProps = {
    onGetObject: getObject,
    onUpdateObject: updateObject,
    onDeleteObject: deleteObject,
    onSetInitialState: setInitialState,
    onShowSnackBar: ShowSnackBar
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(EditDistrictPlate)));