import { _getUsers,_approve,_reject } from '../../../../services/visa/company';
import { ShowSnackBar, ToggleLoader, UpdateReducer } from '../../view'
import { PaginationModel } from '../../../../helpers/services'

export const SET_PENDING_APPROVAL_USER_LIST= 'SET_PENDING_APPROVAL_USER_LIST';
export const GET_PENDING_APPROVAL_USER_LIST= 'GET_PENDING_APPROVAL_USER_LIST';
export const UPDATE_FILTER_MODEL_FOR_PENDING_APPROVAL_USER_LIST = 'UPDATE_FILTER_MODEL_FOR_PENDING_APPROVAL_USER_LIST';
export const APPROVE_PENDING_APPROVAL_USER= 'APPROVE_PENDING_APPROVAL_USER';
export const REJECT_PENDING_APPROVAL_USER= 'REJECT_PENDING_APPROVAL_USER';
export const getList = (page, limit, filterModel) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(ToggleLoader(true));
        dispatch(UpdateReducer(SET_PENDING_APPROVAL_USER_LIST, { loading: true, currentPage: page }))
        _getUsers(page, limit, filterModel).then(response => {
            response = PaginationModel(response, page, limit, response.totalDataCount);
            dispatch(ToggleLoader(false));
            dispatch({
                type: GET_PENDING_APPROVAL_USER_LIST,
                payload: response
            });
            resolve(response)
        }).catch(message => {
            dispatch(UpdateReducer(SET_PENDING_APPROVAL_USER_LIST, { loading: false, currentPage: page }))
            dispatch(ToggleLoader(false));
            dispatch(ShowSnackBar('error', message, 10000));
            reject(message);
        });
    });
};
export const approve = (userId,companyId) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(ToggleLoader(true));
        _approve(userId,companyId).then(response => {
            dispatch(ToggleLoader(false));
            dispatch({
                type: APPROVE_PENDING_APPROVAL_USER,
                payload: response
            });
            dispatch(ShowSnackBar('success', "Approved User.", 10000))
            resolve(response)
        }).catch(message => {
            dispatch(ToggleLoader(false));
            dispatch(ShowSnackBar('error', message, 10000));
            reject(message);
        });
    });
};
export const reject = (userId,companyId) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(ToggleLoader(true));
        _reject(userId,companyId).then(response => {
            dispatch(ToggleLoader(false));
            dispatch({
                type: REJECT_PENDING_APPROVAL_USER,
                payload: response
            });
            dispatch(ShowSnackBar('success', "Rejected User.", 10000))
            resolve(response)
        }).catch(message => {
            dispatch(ToggleLoader(false));
            dispatch(ShowSnackBar('error', message, 10000));
            reject(message);
        });
    });
};
export const updateFilterModel = (filterModel) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: UPDATE_FILTER_MODEL_FOR_PENDING_APPROVAL_USER_LIST,
            payload: filterModel
        });
        resolve(true);
    });
};