import { _getDistrictPlate, _updateDistrictPlate, _deleteDistrictPlate } from '../../../../services/configuration/districtPlate';
import { ShowSnackBar, ToggleLoader } from '../../view'

export const UPDATE_DISTRICT_PLATE = 'UPDATE_DISTRICT_PLATE';
export const DELETE_DISTRICT_PLATE = 'DELETE_DISTRICT_PLATE';
export const SET_INITIAL_DISTRICT_PLATE = "SET_INITIAL_DISTRICT_PLATE";

export const getObject = (id) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(ToggleLoader(true));
        _getDistrictPlate(id).then(response => {
            dispatch(ToggleLoader(false));
            dispatch({
                type: UPDATE_DISTRICT_PLATE,
                payload: response
            });
            resolve(response)
        }).catch(message => {
            dispatch(ToggleLoader(false));
            dispatch(ShowSnackBar('error', message, 10000));
            reject(message);
        });
    });
};

export const updateObject = (requestModel) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(ToggleLoader(true));
        _updateDistrictPlate(requestModel).then(response => {
            dispatch(ToggleLoader(false));
            dispatch({
                type: UPDATE_DISTRICT_PLATE,
                payload: response
            });
            dispatch(ShowSnackBar('success', "District Plate Updated.", 10000))
            resolve(response)
        }).catch(message => {
            dispatch(ToggleLoader(false));
            dispatch(ShowSnackBar('error', message, 10000));
            reject(message);
        });
    });
};

export const deleteObject = (id) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(ToggleLoader(true));
        _deleteDistrictPlate(id).then(response => {
            dispatch(ToggleLoader(false));
            dispatch({
                type: DELETE_DISTRICT_PLATE,
                payload: response
            });
            dispatch(ShowSnackBar('success', "District Plate Deleted.", 10000))
            resolve(response);
        }).catch(message => {
            dispatch(ToggleLoader(false));
            dispatch(ShowSnackBar('error', message, 10000));
            reject(message);
        });
    });
};

export const setInitialState = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: SET_INITIAL_DISTRICT_PLATE,
            payload: true
        });
        resolve(1);
    });
};