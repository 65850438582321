import React from 'react'
import UserImage from '../../../assets/images/user.png'
import { getCurrentUser } from '../../../helpers/account/Authorization'

const UserArea = () => {
    const { Identity, email } = getCurrentUser();
    return (
        <div className="user_area">
            <div className="user_image">
                <img src={UserImage} alt="user" />
            </div>
            <div className="user_informations">
                <p className="user_name">{Identity}</p>
                <p className="user_title">{email}</p>
            </div>
        </div>
    )
}

export default UserArea