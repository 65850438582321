import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import { amber, green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/core/styles';

import { connect } from 'react-redux'
import { HideSnackBar } from '../../redux/actions/view'

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const useStyles1 = makeStyles(theme => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.main,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
}));

function MySnackbarContentWrapper(props) {
    const classes = useStyles1();
    const { className, message, onClose, variant, ...other } = props;
    const Icon = variantIcon[variant];

    return (
        <SnackbarContent
            className={clsx(classes[variant], className)}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
                    <Icon className={clsx(classes.icon, classes.iconVariant)} />
                    {message}
                </span>
            }
            action={[
                <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
                    <CloseIcon className={classes.icon} />
                </IconButton>,
            ]}
            {...other}
        />
    );
}

MySnackbarContentWrapper.propTypes = {
    className: PropTypes.string,
    message: PropTypes.string,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
};

class CustomSnackBar extends React.Component {
    constructor(props) {
        super(props)
        this.handleClose = this.handleClose.bind(this);
    }
    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.props.onHideSnackBar();
    };
    render() {
        return (
            <div>
                <Snackbar
                    anchorOrigin={{
                        vertical: this.props.snackBar.vertical,
                        horizontal: this.props.snackBar.horizontal,
                    }}
                    open={this.props.snackBar.showSnackBar}
                    autoHideDuration={this.props.snackBar.autoHideDuration}
                    onClose={this.handleClose}>
                    <MySnackbarContentWrapper
                        onClose={this.handleClose}
                        variant={this.props.snackBar.messageType}
                        message={this.props.snackBar.message}
                    />
                </Snackbar>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        snackBar: state.viewReducer.snackBar
    };
}

const mapDispatchToProps = {
    onHideSnackBar: HideSnackBar
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomSnackBar);