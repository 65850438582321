import { _getAllConsignments } from '../../../../services/catalog/consignment';
import { ShowSnackBar, ToggleLoader, UpdateReducer } from '../../view'
import { PaginationModel } from '../../../../helpers/services'

export const SET_CONSIGNMENT_LIST= 'SET_CONSIGNMENT_LIST';
export const GET_CONSIGNMENT_LIST= 'GET_CONSIGNMENT_LIST';
export const UPDATE_FILTER_MODEL_FOR_CONSIGNMENT_LIST = 'UPDATE_FILTER_MODEL_FOR_CONSIGNMENT_LIST';

export const getList = (page, limit, filterModel) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(ToggleLoader(true));
        dispatch(UpdateReducer(SET_CONSIGNMENT_LIST, { loading: true, currentPage: page }))
        _getAllConsignments(page, limit, filterModel).then(response => {
            response = PaginationModel(response, page, limit, response.totalDataCount);
            dispatch(ToggleLoader(false));
            dispatch({
                type: GET_CONSIGNMENT_LIST,
                payload: response
            });
            resolve(response)
        }).catch(message => {
            dispatch(UpdateReducer(SET_CONSIGNMENT_LIST, { loading: false, currentPage: page }))
            dispatch(ToggleLoader(false));
            dispatch(ShowSnackBar('error', message, 10000));
            reject(message);
        });
    });
};

export const updateFilterModel = (filterModel) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: UPDATE_FILTER_MODEL_FOR_CONSIGNMENT_LIST,
            payload: filterModel
        });
        resolve(true);
    });
};