import React from 'react'
import AppBar from '../layout/app_bar'

class AnonymousLayout extends React.Component {
    render() {
        const Component = this.props.component;
        const route = this.props.route;
        const renderAppBar = this.props.renderAppBar;
        return (
            <div className="anonymous_layout_container">
                {
                    renderAppBar ?
                        <div className="anonymous_layout_app_bar_area">
                            <AppBar errorMode />
                        </div>
                        : null
                }
                <div className="anonymous_layout_main_area">
                    <Component route={route} />
                </div>
            </div>
        );
    }
}

export default AnonymousLayout;