import React from 'react'
import ListPage from '../../../components/views/pages/listPage'
import ButtonTypes from '../../../constants/view/buttonTypes.json'
import ColumnTypes from '../../../constants/view/columnTypes.json'
import { messages } from '../../../translations/messages'
import { injectIntl } from "react-intl"
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { getList } from '../../../redux/actions/catalog/list/barcode_actions'

const EDIT_PAGE_URL = "/catalog/barcodes/edit/0";

class BarcodeList extends React.Component {
    getId() {
        return this.props.route.match.params.id
    }
    render() {
        const { intl } = this.props;
        return (
            <div id="barcode_list">
                <ListPage
                    breadCrumbData={
                        {
                            title: intl.formatMessage(messages.BARCODES),
                            items: [
                                {
                                    title: intl.formatMessage(messages.DASHBOARD),
                                    link: "/"
                                },
                                {
                                    title: intl.formatMessage(messages.BARCODES)
                                }
                            ]
                        }
                    }
                    buttonsData={
                        [
                            {
                                type: ButtonTypes.FILTER,
                                label: intl.formatMessage(messages.FILTER)
                            }
                        ]
                    }
                    dataTable={
                        {
                            getList: this.props.onGetList,
                            listModel: this.props.listModel,
                            customParameters: { orderId: this.getId() },
                            editPageUrl: EDIT_PAGE_URL,
                            checkCargoStatus:true,
                            columns: [
                                { title: intl.formatMessage(messages.ORDER_NUMBER), field: 'orderNo', },
                                { title: intl.formatMessage(messages.BARCODE), field: 'barcode', },
                                { title: intl.formatMessage(messages.CREATED_DATE),type:'date',  field: 'order.dateCreated', columnType: ColumnTypes.DATE },
                                { title: intl.formatMessage(messages.IS_DELIVERED), field: 'cargoStatus.name' }

                            ]
                        }
                    }
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        listModel: state.listBarcodeReducer.listModel
    };
}

const mapDispatchToProps = {
    onGetList: getList,
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(BarcodeList)));