import React from 'react'
import Card from './card'
import CardWithLastMonthPercentage from './cardWithLastMonthPercentage'
import CardWithProgress from './cardWithProgress'
import Grid from '@material-ui/core/Grid';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import EqualizerIcon from '@material-ui/icons/Equalizer'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'

const DATA = [
    {
        type: "CARD_WITH_LAST_MONTH_PERCENTAGE",
        title: "BUDGET",
        value: "$24,000",
        icon: <AllInclusiveIcon />,
        color: "#E53935",
        extraData: {
            rise: false,
            value: "12%"
        }
    },
    {
        type: "CARD_WITH_LAST_MONTH_PERCENTAGE",
        title: "TOTAL CUSTOMERS",
        value: "1,700",
        icon: <PeopleAltIcon />,
        color: "#43a047",
        extraData: {
            rise: true,
            value: "16%"
        }
    },
    {
        type: "CARD_WITH_PROGRESS",
        title: "TASKS PROGRESS",
        value: "75.5%",
        icon: <EqualizerIcon />,
        color: "#fb8c00",
        extraData: {
            value: "75.5"
        }
    },
    {
        type: "CARD",
        title: "TOTAL PROFIT",
        value: "$23,200",
        icon: <AttachMoneyIcon />,
        color: "#3949ab",
    }
]

class CardView extends React.Component {
    render() {
        return (
            <div className="cards_container">
                <Grid container spacing={2} alignItems="stretch">
                    {
                        DATA.map((item, index) => {
                            switch (item.type) {
                                case "CARD_WITH_LAST_MONTH_PERCENTAGE":
                                    return (
                                        <Grid item md={3} key={index.toString()}>
                                            <CardWithLastMonthPercentage data={item} />
                                        </Grid>
                                    )
                                case "CARD_WITH_PROGRESS":
                                    return (
                                        <Grid item md={3} key={index.toString()}>
                                            <CardWithProgress data={item} />
                                        </Grid>
                                    )
                                default:
                                    return (
                                        <Grid item md={3} key={index.toString()}>
                                            <Card data={item} />
                                        </Grid>
                                    )
                            }
                        })
                    }
                </Grid>
            </div>
        )
    }
}

export default CardView