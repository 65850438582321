import React from 'react'
import Cards from '../../components/views/analytics/cards'

class MainDashboard extends React.Component {
    render() {
        return (
            <div className="main_dashboard_container">
                <Cards />
            </div>
        )
    }
}

export default MainDashboard