import {
    GET_BARCODE_LIST,
    SET_BARCODE_LIST,
    UPDATE_FILTER_MODEL_FOR_BARCODE_LIST
} from '../../../actions/catalog/list/barcode_actions'

const initialState = {
    listModel: {
        loading: true,
        data: [],
        filterModel:{
            args: {
                search: '',
                page: 1,
                limit: 1000,
                statements: []
            },
            orderId: 0
        },
        pagination: {
            currentPage: 1,
            limit: 10,
            totalDataCount: 0,
            totalPageCount: 0
        }
    }
};

function reducer(state = initialState, { type, payload }) {
    switch (type) {
        case UPDATE_FILTER_MODEL_FOR_BARCODE_LIST:
            return { ...state, listModel: { ...state.listModel, filterModel: { ...payload }, pagination: { ...state.listModel.pagination, currentPage: payload.page, limit: payload.limit } } }
        case GET_BARCODE_LIST:
            return { ...state, listModel: { ...state.listModel, ...payload } }
        case SET_BARCODE_LIST:
            return { ...state, listModel: { ...state.listModel, loading: payload.loading, pagination: { ...state.listModel.pagination, currentPage: payload.currentPage } } }
        default:
            return { ...state };
    }
}

export default reducer;