import React from 'react';
import LeftMenu from '../layout/left_drawer_menu'
import AppBar from '../layout/app_bar'

class Layout extends React.Component {
    render() {
        const Component = this.props.component;
        const route = this.props.route;
        return (
            <div className="LayoutContainer">
                <div className="layout_app_main_area">
                    <div className="layout_app_bar_area">
                        <AppBar />
                    </div>
                    <div className="layout_left_menu_and_main_area">
                        <div className="layout_left_menu_area">
                            <LeftMenu />
                        </div>
                        <div className="layout_main_area">
                            <Component route={route} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Layout;