import React from 'react'
import EditPage from '../../../components/views/pages/editPage'
import Form from '../../../components/views/pages/form'
import ButtonTypes from '../../../constants/view/buttonTypes.json'
import { Grid } from '@material-ui/core'
import { TextField, SelectBox, Switch } from '../../../components/common'
import { mergeObject } from '../../../helpers/common/objectArray';
import { messages } from '../../../translations/messages'
import { injectIntl } from "react-intl"
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import Services from '../../../constants/services/list_services.json'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

import { getObject, updateObject, deleteObject, setInitialState } from '../../../redux/actions/catalog/edit/barcode_actions'
import { ShowSnackBar } from '../../../redux/actions/view'
import ExtraInformations from '../../../components/views/pages/editPage/extraInformations'

const LIST_PAGE_URL = "/catalog/consignment/list";
const containerStyle = {
    width: '-webkit-fill-available',
    height: '400px' 
};
class EditBarcode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            model: this.props.model,
            center: { lat: this.props.model.latitude, lng: this.props.model.longitude }
        }
    }

    propsToState() {
        const { model } = this.props;
        this.setState({
            model: model
        });
        var center={lat:0,lng:0};
        center.lat=this.props.model.latitude;
        center.lng=this.props.model.longitude;
        this.setState({ center: center });
    }
    getId() {
        return this.props.route.match.params.id
    }
  
    returnToListPage() {
        this.props.history.push(LIST_PAGE_URL);
    }
   
    componentDidMount() {
        const id = this.getId();
        this.props.onSetInitialState().then(resp => this.propsToState());
        if (id > 0)
            this.props.onGetObject(id)
                .then(response => this.propsToState())
                .catch(err => this.returnToListPage())
    }
    render() {
        const { intl } = this.props;
        return (
            <div id="barcode_edit">
                <EditPage
                    breadCrumbData={
                        {
                            title: intl.formatMessage(messages.LOCATION_INFO),
                            items: [
                                {
                                    title: intl.formatMessage(messages.DASHBOARD),
                                    link: "/"
                                },
                                {
                                    title: intl.formatMessage(messages.CONSIGNMENTS),
                                    link: LIST_PAGE_URL
                                },
                                {
                                    title: intl.formatMessage(messages.BARCODE)
                                }
                            ]
                        }
                    }
                    buttonsData={
                        [
                            {
                                type: ButtonTypes.GO_BACK
                            }
                        ]
                    }>
                    <Grid container spacing={5}>
                        <Grid item md={12}>
                            <Form
                                title={intl.formatMessage(messages.LOCATION_INFO)}
                            >
                                <Grid container spacing={2}>
                                    <Grid item md={12}>
                                        <LoadScript
                                            googleMapsApiKey="AIzaSyAl9Jdkt8udvUeOkD8DYDpc17inq8kSVY4"
                                            libraries={["places"]}
                                        >
                                            <GoogleMap
                                                mapContainerStyle={containerStyle}
                                                center={this.state.center}
                                                clickableIcons={true}
                                                zoom={18}
                                            >
                                                {
                                                    <Marker
                                                        position={this.state.center} />

                                                }
                                            </GoogleMap>
                                        </LoadScript>
                                    </Grid>
                                    <Grid item md={6}>
                                        <TextField
                                            disabled={true}
                                            label={intl.formatMessage(messages.BARCODE)}
                                            placeholder={intl.formatMessage(messages.BARCODE)}
                                            value={this.state.model.barcode}
                                            type="text"
                                        />
                                    </Grid>
                                    <Grid item md={6}>
                                        <TextField
                                            disabled={true}
                                            label={intl.formatMessage(messages.SIGNAL_TIME)}
                                            placeholder={intl.formatMessage(messages.SIGNAL_TIME)}
                                            value={this.state.model.signalTime}
                                            type="datetime-local"
                                        />
                                    </Grid>
                                </Grid>
                            </Form>
                        </Grid>
                    </Grid>
                </EditPage>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        model: state.editBarcodeReducer.model,
        requestModel: state.editBarcodeReducer.requestModel,
        loading: state.viewReducer.loader.showLoader,
        disabled: state.viewReducer.loader.disabled
    };
}

const mapDispatchToProps = {
    onGetObject: getObject,
    onUpdateObject: updateObject,
    onDeleteObject: deleteObject,
    onSetInitialState: setInitialState,
    onShowSnackBar: ShowSnackBar
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(EditBarcode)));