export const ConvetDateToDDMMYYHHMM = (date, brackets = '-') => {
    if (!date)
        return "";
    var d = new Date(date),
        month = d.getMonth() + 1,
        day = d.getDate(),
        year = d.getFullYear(),
        hour = d.getHours(),
        minute = d.getMinutes();

    if (month < 10)
        month = '0' + month;

    if (day < 10)
        day = '0' + day;

    if (hour < 10)
        hour = '0' + hour;

    if (minute < 10)
        minute = '0' + minute;

    return [day, month, year].join(brackets) + " " + [hour, minute].join(':');
}

export const ConvetDateToMMYYYY = (date, brackets = '/') => {
    if (!date)
        return "";
    var d = new Date(date),
        month = d.getMonth() + 1,
        day = d.getDate(),
        year = d.getFullYear(),
        hour = d.getHours(),
        minute = d.getMinutes();

    if (month < 10)
        month = '0' + month;

    if (day < 10)
        day = '0' + day;

    if (hour < 10)
        hour = '0' + hour;

    if (minute < 10)
        minute = '0' + minute;

    return [month, year].join('-');
}
export const ConvetDateToDDMMYYYY = (date, brackets = '/') => {
    if (!date)
        return "";
        
    var d = new Date(date),
        month = d.getMonth() + 1,
        day = d.getDate(),
        year = d.getFullYear(),
        hour = d.getHours(),
        minute = d.getMinutes();

    if (month < 10)
        month = '0' + month;

    if (day < 10)
        day = '0' + day;

    if (hour < 10)
        hour = '0' + hour;

    if (minute < 10)
        minute = '0' + minute;

    return [day, month, year].join('-');
}

export const cdnFileControl = (file) => {
    if (!file)
        return ''
    return (file.indexOf('https://') > -1 || file.indexOf('http://') > -1) ? '' : file;
}