import React from 'react'
import { Grid } from '@material-ui/core'
import { ConvetDateToDDMMYYHHMM } from '../../helpers/common'

const Field = ({ label, value, format, coloredText }) => {
    const renderValue = () => {
        var result = value;
        switch (format) {
            case "DATE_TIME":
                result = ConvetDateToDDMMYYHHMM(value);
                break;
            default:
                result = value;
                break;
        }
        return result;
    }
    return (
        <div className="default_field">
            <Grid container spacing={2}>
                {
                    label ? <Grid item md={6}>
                        <div className="left_area">
                            <span>{label} :</span>
                        </div>
                    </Grid> : null
                }

                <Grid item md={label ? 6 : 12}>
                    <div className="right_area">
                        <span className={coloredText}>{renderValue()}</span>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default Field;