import { getService, postService } from '../index'
import { getServiceErrorMessage, handleServiceResult } from '../../helpers/services'

import BaseFilterModel from '../../constants/services/filter/filterModel.json'

export const _getAllOrderItems = (customParameters, filterModel) => new Promise((resolve, reject) => {
    const { orderId } = customParameters;
    filterModel.orderId=orderId;
    postService('/api/OrderItem/getmyorderitems', filterModel).then(response => {
        const _handleServiceResult = handleServiceResult(response);
        if (_handleServiceResult.success) {
            const data = { data: response.data, pagination: { totalDataCount: response.totalDataCount } };
            resolve(data);
        } else {
            reject(getServiceErrorMessage(response));
        }
    }).catch(error => {
        reject(getServiceErrorMessage(error.response));
    })
});

export const _getLocationInfo = (id) => new Promise((resolve, reject) => {
    getService('/api/OrderItem/getlocationinfo?orderItemId=' + id).then(response => {
        const _handleServiceResult = handleServiceResult(response);
        if (_handleServiceResult.success) {
            if (response.data) {
                resolve(response.data);
            } else {
                reject('UNDEFINED ERROR (E_01)');
            }
        } else {
            reject(getServiceErrorMessage(response));
        }
    }).catch(error => {
        reject(getServiceErrorMessage(error.response));
    })
});

export const _updateOrderItem = (model) => new Promise((resolve, reject) => {
    postService('/api/OrderItem/update', model).then(response => {
        const _handleServiceResult = handleServiceResult(response);
        if (_handleServiceResult.success) {
            resolve(response.data);
        } else {
            reject(getServiceErrorMessage(response));
        }
    }).catch(error => {
        reject(getServiceErrorMessage(error.response));
    })
});

export const _deleteOrderItem = (id) => new Promise((resolve, reject) => {
    postService('/api/OrderItem/delete?id=' + id,).then(response => {
        const _handleServiceResult = handleServiceResult(response);
        if (_handleServiceResult.success) {
            resolve(response.data);
        } else {
            reject(getServiceErrorMessage(response));
        }
    }).catch(error => {
        reject(getServiceErrorMessage(error.response));
    })
});
