import React from 'react'
import { setLocale, getLocale } from '../../helpers/common/localization'
import Languages from '../../translations'

class LanguageSelect extends React.Component {
    constructor(props){
        super(props);
        this.customTemplateOnChange = this.customTemplateOnChange.bind(this);
    }
    onChange(e) {
        const { value } = e.target;
        setLocale(value);
    }
    customTemplateOnChange(keyName){
        setLocale(keyName);
    }
    render() {
        const { selectMode } = this.props;
        return (
            <div className="languageSelectBox">
                {
                    selectMode ?
                        <select name="languages" id="languages" onChange={this.onChange} value={getLocale()}>
                            {Object.keys(Languages).map((keyName, i) => {
                                return <option value={keyName} key={keyName}>{keyName.split("-")[0].toUpperCase()}</option>
                            })}
                        </select>
                        :
                        <div className="customTemplate">
                            <ul>
                                {Object.keys(Languages).map((keyName, i) => {
                                    return <li onClick={()=>this.customTemplateOnChange(keyName)} className={getLocale()===keyName ? "active":""} key={i.toString()}>{keyName.split("-")[0].toUpperCase()}</li>
                                })}
                            </ul>
                        </div>
                }

            </div>
        )
    }
}

export default LanguageSelect