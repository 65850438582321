import React from 'react'

class SignUp extends React.Component{
    render(){
        return(
            <div>Sign Up</div>
        )
    }
}

export default SignUp