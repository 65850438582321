import {
    UPDATE_BARCODE,
    SET_INITIAL_BARCODE
} from '../../../actions/catalog/edit/barcode_actions'
import { copyObjectWithControl } from '../../../../helpers/common/objectArray'

const initialState = {
    model: {
        latitude: 0,
        longitude: 0,
        signalTime: "",
        barcode:""
    },
    requestModel: {
        id: 0,
        name: "",
        code: "",
        plate: "",
        courierCode: "",
        statusID: 0
    }
};

function reducer(state = initialState, { type, payload }) {
    switch (type) {
        case SET_INITIAL_BARCODE:
            return { ...initialState }
        case UPDATE_BARCODE:
            return { ...state, model: { ...copyObjectWithControl(state.model, payload) } }
        default:
            return { ...state };
    }
}

export default reducer;