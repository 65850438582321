import {
    GET_PENDING_APPROVAL_USER_LIST,
    SET_PENDING_APPROVAL_USER_LIST,
    UPDATE_FILTER_MODEL_FOR_PENDING_APPROVAL_USER_LIST
} from '../../../actions/visa/list/pending_approval_user_actions'

const initialState = {
    listModel: {
        loading: true,
        data: [],
        filterModel: {
            search: '',
            page: 1,
            limit: 1000,
            statements: [
                {
                    propertyName: "IsApproved",
                    operation: 0,
                    value: false,
                    combine: "AND"
                }
            ]
        },
        pagination: {
            currentPage: 1,
            limit: 10,
            totalDataCount: 0,
            totalPageCount: 0
        }
    }
};

function reducer(state = initialState, { type, payload }) {
    switch (type) {
        case UPDATE_FILTER_MODEL_FOR_PENDING_APPROVAL_USER_LIST:
            return { ...state, listModel: { ...state.listModel, filterModel: { ...payload }, pagination: { ...state.listModel.pagination, currentPage: payload.page, limit: payload.limit } } }
        case GET_PENDING_APPROVAL_USER_LIST:
            return { ...state, listModel: { ...state.listModel, ...payload } }
        case SET_PENDING_APPROVAL_USER_LIST:
            return { ...state, listModel: { ...state.listModel, loading: payload.loading, pagination: { ...state.listModel.pagination, currentPage: payload.currentPage } } }
        default:
            return { ...state };
    }
}

export default reducer;