import { getService, postService } from '../index'
import { getServiceErrorMessage, handleServiceResult } from '../../helpers/services'

import BaseFilterModel from '../../constants/services/filter/filterModel.json'

export const _getAllConsignments = (filterModel) => new Promise((resolve, reject) => {
    postService('/api/Order/getall', filterModel).then(response => {
        const _handleServiceResult = handleServiceResult(response);
        if (_handleServiceResult.success) {
            const data = { data: response.data, pagination: { totalDataCount: response.totalDataCount } };
            resolve(data);
        } else {
            reject(getServiceErrorMessage(response));
        }
    }).catch(error => {
        reject(getServiceErrorMessage(error.response));
    })
});

export const _getConsignment = (id) => new Promise((resolve, reject) => {
    getService('/api/Order/getbyid?countryId=' + id).then(response => {
        const _handleServiceResult = handleServiceResult(response);
        if (_handleServiceResult.success) {
            if (response.data) {
                resolve(response.data);
            } else {
                reject('UNDEFINED ERROR (E_01)');
            }
        } else {
            reject(getServiceErrorMessage(response));
        }
    }).catch(error => {
        reject(getServiceErrorMessage(error.response));
    })
});

export const _updateConsignment= (model) => new Promise((resolve, reject) => {
    postService('/api/Order/update', model).then(response => {
        const _handleServiceResult = handleServiceResult(response);
        if (_handleServiceResult.success) {
            resolve(response.data);
        } else {
            reject(getServiceErrorMessage(response));
        }
    }).catch(error => {
        reject(getServiceErrorMessage(error.response));
    })
});

export const _deleteConsignment = (id) => new Promise((resolve, reject) => {
    postService('/api/Order/delete?id=' + id,).then(response => {
        const _handleServiceResult = handleServiceResult(response);
        if (_handleServiceResult.success) {
            resolve(response.data);
        } else {
            reject(getServiceErrorMessage(response));
        }
    }).catch(error => {
        reject(getServiceErrorMessage(error.response));
    })
});
