import SignIn from '../views/account/sign_in'
import SignUp from '../views/account/sign_up'

const sessionRoutes = {
    SignIn: {
        component: SignIn,
        path: '/sign-in'
    },
    SignUp: {
        component: SignUp,
        path: '/sign-up'
    }
};

export default sessionRoutes