import { combineReducers } from 'redux'

import userReducer from './account/user_reducer'
import viewReducer from './view'

import editDistrictPlateReducer from './configuration/edit/district_plate_reducer'
import listDistrictPlateReducer from './configuration/list/district_plate_reducer'

import listCompanyReducer from './visa/list/company_reducer'
import listCompanyUserReducer from './visa/list/companyuser_reducer'
import listPendingApprovalUserReducer from './visa/list/pending_approval_user_reducer'


import listConsignmentReducer from './catalog/list/consignment_reducer'
import listBarcodeReducer from './catalog/list/barcode_reducer'
import editBarcodeReducer from './catalog/edit/barcode_reducer'

const rootReducer = combineReducers({
    userReducer,
    viewReducer,
    editDistrictPlateReducer,
    listDistrictPlateReducer,
    listCompanyReducer,
    listConsignmentReducer,
    listBarcodeReducer,
    editBarcodeReducer,
    listCompanyUserReducer,
    listPendingApprovalUserReducer
});

export default rootReducer;