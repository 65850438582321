import React from 'react';
import Logo from '../../assets/images/logo.png'
import { Link } from 'react-router-dom'
import { LogoutUser } from '../../redux/actions/account/user_actions'
import { ToggleConfigurationDrawer } from '../../redux/actions/view/'
import { connect } from 'react-redux'
import ConfigurationDrawer from '../views/drawers/configuration'

import IconButton from '@material-ui/core/IconButton'
import NotificationsIcon from '@material-ui/icons/Notifications'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import SettingsIcon from '@material-ui/icons/Settings'
import SearchIcon from '@material-ui/icons/Search'

class AppBar extends React.Component {
    render() {
        const { errorMode } = this.props;
        return (
            <div className="app_bar_container">
                <div className="icon_area">
                    <Link to="/">
                        <img src={Logo} alt="Console" />
                    </Link>
                </div>
                <div className="buttons_area">
                    {!errorMode ?
                        <>
                            <IconButton>
                                <SearchIcon />
                            </IconButton>
                            <IconButton>
                                <NotificationsIcon />
                            </IconButton>
                            <IconButton onClick={() => this.props.onToggleConfigurationDrawer(true)}>
                                <SettingsIcon />
                            </IconButton>
                            <IconButton onClick={() => this.props.onLogoutUser()}>
                                <ExitToAppIcon />
                            </IconButton>

                            <ConfigurationDrawer />
                        </>
                        : null
                    }

                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.userReducer
    };
}
const mapDispatchToProps = {
    onLogoutUser: LogoutUser,
    onToggleConfigurationDrawer: ToggleConfigurationDrawer
}

export default connect(mapStateToProps, mapDispatchToProps)(AppBar)