import React from 'react';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default function SwitchComponent({ label, onChange, value, disabled, labelPlacement, margin }) {
    return (
        <FormControlLabel
            value="start"
            style={{ margin: margin }}
            control={
                <Switch
                    disabled={disabled}
                    checked={(value > 0)}
                    onChange={onChange}
                    color="primary" />
            }
            label={label}
            labelPlacement={labelPlacement ? labelPlacement : "start"}
        />
    );
}