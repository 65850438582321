import ObjectTypes from '../../enums/objectTypes.json'

export const filterArrayKeys = (array, keys, type) => {
    let _array = [];
    const keyArray = keys.split(',');
    for (let item of array) {
        if (type === ObjectTypes.Array) {
            _array.push(item[keys]);
        } else {
            let _item = {};
            for (let keyItem of keyArray) {
                _item[keyItem] = item[keyItem];
            }
            _array.push(_item);
        }
    }
    return _array;
}

export const mergeObject = (targetObject, sourceObject) => {
    let _targetObject = { ...targetObject };
    for (const key of Object.keys(sourceObject)) {
        if (_targetObject.hasOwnProperty(key)) {
            var lastChar = key[key.length - 1];
            if (lastChar.toLocaleLowerCase() === "s") {
                delete _targetObject[key];
                var IdsKey = key.substr(0, (key.length - 1)) + "Ids";
                _targetObject[IdsKey] = filterArrayKeys(sourceObject[key], 'id', ObjectTypes.Array)
            } else {
                _targetObject[key] = sourceObject[key];
            }

        } else if (_targetObject.hasOwnProperty(key + "ID")) {
            _targetObject[key + "ID"] = sourceObject[key]["id"];
        }
    }
    return _targetObject;
}

export const copyObjectWithControl = (targetObject, sourceObject) => {
    let _targetObject = { ...targetObject };
    for (const key of Object.keys(sourceObject)) {
        _targetObject[key] = sourceObject[key];
    }
    return _targetObject;
}