import React from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { messages } from '../../../translations/messages'
import { injectIntl } from "react-intl"
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { ToggleConfigurationDrawer } from '../../../redux/actions/view/'
import DrawerContainer from './drawerContrainer';
import LanguageSelect from '../../common/languages'

class ConfigurationDrawer extends React.Component {

    constructor(props) {
        super(props);
        this.toggleDrawer = this.toggleDrawer.bind(this);
    }

    toggleDrawer = (show) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        this.props.onToggleConfigurationDrawer(show);
    };

    render() {
        const { intl } = this.props;
        return (
            <div>
                <SwipeableDrawer
                    anchor='right'
                    open={this.props.show}
                    onClose={this.toggleDrawer(false)}
                    onOpen={this.toggleDrawer(true)}>
                    <DrawerContainer title={intl.formatMessage(messages.CONFIGURATION)}>
                        <div className="configuration_drawer_area">
                            <div className="configuration_item">
                                <div className="key">
                                    <span>{intl.formatMessage(messages.CHANGE_LANGUAGE)} : </span>
                                </div>
                                <div className="value">
                                    <LanguageSelect />
                                </div>
                            </div>
                        </div>
                    </DrawerContainer>
                </SwipeableDrawer>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        show: state.viewReducer.drawer.configuration.show
    };
}

const mapDispatchToProps = {
    onToggleConfigurationDrawer: ToggleConfigurationDrawer
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(ConfigurationDrawer)));