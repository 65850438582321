import React from 'react'
import ListPage from '../../../components/views/pages/listPage'
import ButtonTypes from '../../../constants/view/buttonTypes.json'
import { messages } from '../../../translations/messages'
import { injectIntl } from "react-intl"
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { getList } from '../../../redux/actions/configuration/list/district_plate_actions'

const EDIT_PAGE_URL = "/configuration/district-plate/edit/0";

class DistrictPlateList extends React.Component {
    render() {
        const { intl } = this.props;
        return (
            <div id="district_plate_list">
                <ListPage
                    breadCrumbData={
                        {
                            title: intl.formatMessage(messages.ALL_DISTRICT_PLATE),
                            items: [
                                {
                                    title: intl.formatMessage(messages.DASHBOARD),
                                    link: "/"
                                },
                                {
                                    title: intl.formatMessage(messages.DISTRICT_PLATES)
                                }
                            ]
                        }
                    }
                    buttonsData={
                        [
                            {
                                type: ButtonTypes.FILTER,
                                label: intl.formatMessage(messages.FILTER)
                            },
                            {
                                type: ButtonTypes.ADD_NEW,
                                label: intl.formatMessage(messages.NEW_DISTRICT_PLATE),
                                link: EDIT_PAGE_URL,
                                variant: "contained",
                                color: "primary",
                            }
                        ]
                    }
                    dataTable={
                        {
                            getList: this.props.onGetList,
                            listModel: this.props.listModel,
                            editPageUrl: EDIT_PAGE_URL,
                            columns: [
                                { title: intl.formatMessage(messages.ID), field: 'id', },
                                { title: intl.formatMessage(messages.COURIER_CODE), field: 'courierCode' },
                                { title: intl.formatMessage(messages.COURIER_NAME), field: 'courierName' },
                                { title: intl.formatMessage(messages.PLATE), field: 'plate' },
                                { title: intl.formatMessage(messages.CODE), field: 'code' }
                            ]
                        }
                    }
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        listModel: state.listDistrictPlateReducer.listModel
    };
}

const mapDispatchToProps = {
    onGetList: getList,
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(DistrictPlateList)));