import React from 'react'
import Button from '@material-ui/core/Button'
import { useHistory } from "react-router-dom"
import AddCircleIcon from '@material-ui/icons/AddCircle'
import FilterListIcon from '@material-ui/icons/FilterList'
import ButtonTypes from '../../../../constants/view/buttonTypes.json'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import SaveIcon from '@material-ui/icons/Save'
import { messages } from '../../../../translations/messages'
import { useIntl } from "react-intl"

const DEFINED_BUTTONS = [
    ButtonTypes.GO_BACK,
    ButtonTypes.CUSTOM
]

const Buttons = ({ data }) => {
    const intl = useIntl();
    let history = useHistory();

    const handleClick = (e, item) => {
        if (item.link)
            history.push(item.link);
        else if (item.action)
            item.action(e);
    }

    const buttonIconByType = (buttonType) => {
        switch (buttonType) {
            case ButtonTypes.ADD_NEW:
                return <AddCircleIcon />;
            case ButtonTypes.FILTER:
                return <FilterListIcon />;
            case ButtonTypes.GO_BACK:
                return <ArrowBackIosIcon />
            case ButtonTypes.SAVE:
                return <SaveIcon />
            default:
                break;
        }
    }

    const isDefinedButton = (buttonType) => {
        let result = false;
        for (const key of DEFINED_BUTTONS) {
            if (buttonType === key) {
                result = true;
                break;
            }
        }
        return result;
    }

    const renderDefinedButton = (buttonType, customContent) => {
        switch (buttonType) {
            case ButtonTypes.GO_BACK:
                return (
                    <Button
                        onClick={history.goBack}>
                        {intl.formatMessage(messages.CANCEL)}
                    </Button>
                )
            case ButtonTypes.CUSTOM:
                return (
                    customContent
                )
            default:
                break;
        }
    }

    return (
        data ?
            <div className="page_template_buttons_container">
                <ul>
                    {data.map((item, i) => {
                        return (
                            <li>
                                {
                                    isDefinedButton(item.type) ?
                                        renderDefinedButton(item.type, item.customContent)
                                        :
                                        <Button
                                            variant={item.variant}
                                            color={item.color}
                                            onClick={(e) => handleClick(e, item)}
                                            startIcon={buttonIconByType(item.type)}>
                                            {item.label}
                                        </Button>
                                }
                            </li>
                        )
                    })}
                </ul>
            </div> : null
    )
}

export default Buttons