import React from 'react'

class PermissionDenied extends React.Component{
    render(){
        return(
            <div>Permission Denied</div>
        )
    }
}

export default PermissionDenied