import React from 'react'
import { Link } from "react-router-dom";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const BreadCrumb = ({ data }) => {
    const { title, items } = data;
    return (
        <div className="breadcrumb_container">
            <div className="breadcrumb_area">
                <ul>
                    {items.map((item, i) => {
                        return (
                            (i + 1) !== items.length ?
                                <li className="secondary_text_color">
                                    <Link to={item.link}>{item.title}</Link> <ChevronRightIcon />
                                </li>
                                :
                                <li>
                                    <p to={item.link}>{item.title}</p>
                                </li>
                        )
                    })}
                </ul>
                <div className="clear_both"></div>
            </div>
            <div className="title_area">
                {title}
            </div>
        </div>
    )
}

export default BreadCrumb