import React from 'react'
import BreadCrumb from './breadcrumb'
import Buttons from './buttons'
import { Grid } from '@material-ui/core'
import DataTable from '../../common/dataTable'

class ListPage extends React.Component {
    render() {
        const { breadCrumbData, buttonsData, dataTable } = this.props;
        return (
            <div className="list_page_template_container">
                <div className="top_area">
                    <Grid container spacing={2}>
                        <Grid item md={6}>
                            <div className="left_area">
                                <BreadCrumb data={breadCrumbData} />
                            </div>
                        </Grid>
                        <Grid item md={6}>
                            <div className="right_area">
                                <Buttons data={buttonsData} />
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div className="bottom_area">
                    <div className="data_table_container">
                        <DataTable
                            data={dataTable}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default ListPage;